import React from "react"

import WebsiteLayout from '../components/site/website_layout'
import Signup from '../components/site/signup'
import { RequireNoUser } from "../components/check_user"

export default () => (
  <RequireNoUser>
    <WebsiteLayout>
      <Signup />
    </WebsiteLayout>
  </RequireNoUser>
);