import React, { useState } from "react"

// TODO support redirect based on query params
// TODO support javascript method for posting login (see login.js)

import Loader from "react-loader-spinner"

import Banner from "./banner"

import smallform from "./smallform.module.css"

import { navigate, Link } from "../../services/routes" // "gatsby"
import { signup } from "../../services/auth"
import { isEmailValid, checkPassword } from "./common"
import routes from "../../services/routes"

function validateSignupForm({firstname, email, password, verifyPassword}) {
	let valid = true;
	let reason = "";
	if (valid && !isEmailValid(email)) {
		valid = false;
		reason = "The email address you entered is not valid.";
	}
	if (valid && firstname.length === 0) {
		valid = false;
		reason = "You did not enter your first name.";
	} else {
		// Force firstname to be capitalized
		firstname = firstname.charAt(0).toUpperCase() + firstname.substr(1);
	}
	if (valid) {
		const passwordCheck = checkPassword(password);
		if (!passwordCheck.valid) {
			valid = false;
			reason = passwordCheck.reason;
		}
	}
	if (valid && !(password === verifyPassword)) {
		valid = false;
		reason = "The passwords you entered don't match.";
	}
	return { valid, reason };
};

export default () => {

	const [state, setState] = useState({
		loading: false,
		formData: {
			firstname: '',
			email: '',
			password: '',
			verifyPassword: '',
		},
		rejectReason: '',
	});
	const update = (event) => {
		if (event.target.name==="firstname") {
			let firstname = event.target.value;
			event.target.value = firstname.charAt(0).toUpperCase() + firstname.substr(1);
		}
		let newState = {
			...state,
			formData: {
				...state.formData,
				[event.target.name]: event.target.value,
			},
			rejectReason: '', // always reset when form changes
		};
		setState(newState);
	}
	const submit = async (event) => {
		event.preventDefault();
		let result = validateSignupForm(state.formData);
		if (result.valid) {
			setState({...state, loading: true});
			let signupResult = await signup(state.formData);
			if (signupResult.success) {
				navigate(routes.confirm);
			} else {
				setState({
					...state,
					rejectReason: signupResult.reason,
					loading: false,
				});
			}
		} else {
			let newState = {
				...state,
				rejectReason: result.reason,
			}
			setState(newState);
		}
	}

	return (
	<>	
		<Banner title="Sign Up" link={routes.signup} />
		<section style={{textAlign:"center"}}>
			<div className="container">
				<p style={{marginTop:"20px"}}>
					Already have an account? <Link to={routes.login}>Log in </Link>instead.
				</p>
				<div style={{maxWidth:"400px", margin:"0 auto"}}>
					<form className={smallform.smallform} id="signup_form">
						<input
							className={`form-control ${smallform.formTextTop} ${smallform.formControl}`}
							type="text"
							placeholder="First name"
							required={true}
							autoFocus={true}
							name="firstname"
							onChange={update}
						/>
						<input
							className={`form-control ${smallform.formTextMiddle} ${smallform.formControl}`}
							type="text"
							placeholder="Email address"
							required={true}
							name="email"
							onChange={update}
						/>
						<input
							className={`form-control ${smallform.formTextMiddle} ${smallform.formControl}`}
							type="password"
							placeholder="Password (min 10 characters)"
							required={true}
							name="password"
							onChange={update}
						/>
						<input
							className={`form-control ${smallform.formTextBottom} ${smallform.formControl}`}
							type="password"
							placeholder="Verify Password"
							required={true}
							name="verifyPassword"
							onChange={update}
						/>
						<br />
						{(state.rejectReason.length > 0) &&
							<p style={{color:"red"}} dangerouslySetInnerHTML={{__html:state.rejectReason}}></p>
						}
						{ state.loading &&
							<Loader type="Rings" color={"rgb(59,172,240)"} height={50} />
						}
						{ !state.loading &&
							<button style={{border:"none"}} className="main_btn" type="submit" onClick={submit}>
								Create Account
							</button>
						}
					</form>
					{/*
					<p style={{marginTop:"20px"}}>
						or sign up with <Link to={routes.auth_google}>Google</Link>
					</p>
					*/}
				</div>
			</div>
		</section>
	</>
	);
};
